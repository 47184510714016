/** @jsx jsx **/
import { jsx } from 'theme-ui'
import { FC } from 'react'
import NewsletterTemplate from '~/templates/page-newsletter'

type Props = {}

const Newsletter: FC<Props> = () => {
  return <NewsletterTemplate />
}

export default Newsletter
