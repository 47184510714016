/** @jsx jsx **/
import { jsx, Container, Styled, Text } from 'theme-ui'
import { FC } from 'react'
import Layout from '~/components/layout'
import { graphql, useStaticQuery } from 'gatsby'
import MarkdownIt from '~/components/MarkdownIt'
import KlaviyoForm from '~/utils/klaviyoNewsletterForm'

type Props = {}

type QueryResult = {
  pageData: {
    frontmatter: {
      title: string
    }
    html: string
  }
}

const NewsletterTemplate: FC<Props> = () => {
  const klaviyoSmsNewsletterFormId = process.env.GATSBY_KLAVIYO_SMSPAGE_NEWSLETTER_FORM_ID || ''

  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "newsletter" } }) {
        frontmatter {
          title
        }
        html
      }
    }
  `)
  const {
    frontmatter: { title },
    html: content,
  } = query.pageData

  return (
    <Layout headerVariant="default">
      <Container
        variant="containerPrimary"
        sx={{
          my: [50, null, 100],
        }}
      >
        <Container
          sx={{
            maxWidth: 576,
            p: 0,
          }}
        >
          <KlaviyoForm formId={klaviyoSmsNewsletterFormId} loading="Loading..." />
        </Container>
      </Container>
    </Layout>
  )
}

export default NewsletterTemplate
